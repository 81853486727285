import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectCoverflow, Pagination, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './application.css';

import slide_image_1 from '../../resources/images/decolourizing.jpg';
import slide_image_2 from '../../resources/images/medicine.jpg';
import slide_image_3 from '../../resources/images/gas_absorbent.webp'; 
import slide_image_4 from '../../resources/images/metal_recovery.png';
import slide_image_5 from '../../resources/images/chemical_recovery.jpg';

SwiperCore.use([EffectCoverflow, Pagination, Navigation]);

const Applications = () => {
  const applications = [
    {
      title: 'Decolorizing',
      image: slide_image_1
    },
    {
      title: 'Medicine',
      image: slide_image_2
    },
    {
      title: 'Gas Absorbent',
      image: slide_image_3
    },
    {
      title: 'Metal Recovery',
      image: slide_image_4
    },
    {
      title: 'Chemical Recovery',
      image: slide_image_5
    }
  ];

  return (
    <div className="container font-raleway bg-neutral-100 text-black1" id='applications'>
      <h1 className="heading text-3xl md:text-5xl font-bold text-darkGreen1">Applications</h1>
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        pagination={{ el: '.swiper-pagination', clickable: true }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className="swiper_container"
      >
        {/* Map through application data and render each as a SwiperSlide */}
        {applications.map((app, index) => (
          <SwiperSlide key={index}>
            <div className="card">
              <img className='' src={app.image} alt="Application" />
              <div className="content bg-[#E8F5E9]">
                <h2 className='font-bold text-black1'>{app.title}</h2>
              </div>
            </div>
          </SwiperSlide>
        ))}
        {/* Slider controls */}
        <div className="slider-controls">
          <div className="swiper-button-prev slider-arrow">
            <ion-icon className="carousel-arrow text-green1" name="arrow-back-outline"></ion-icon>
          </div>
          <div className="swiper-button-next slider-arrow">
            <ion-icon name="arrow-forward-outline"></ion-icon>
          </div>
          {/* <div className="swiper-pagination"></div> */}
        </div>
      </Swiper>
    </div>
  );
}

export default Applications;
