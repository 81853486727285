import React from 'react';
import qualityImage from '../../resources/images/quality_carbon_img.jpg';
import qualityCheckLogo from '../../resources/images/quality_check_logo.png';

const Quality = () => {
  return (
    <section className="font-raleway py-5 text-black1 relative" id='quality'>
      <div className="container mx-auto px-4 flex flex-col md:flex-row items-center justify-center gap-x-5">
        <div className="md:w-1/2 relative">
          <img src={qualityImage} alt="Quality" className="w-full rounded-lg shadow-lg" />
          <img src={qualityCheckLogo} alt="Quality Check" className="absolute top-0 left-0 w-24 md:w-32 mt-4 ml-4 z-10" />
        </div>
        <div className="md:w-1/2 mb-8 md:mb-0">
          <div className="text-center md:text-left">
            <h2 className="text-3xl md:text-5xl font-bold text-darkGreen1 mb-6 pt-10 lg:pt-0">Quality Assurance</h2>
            <p className="text-lg md:text-xl mb-8 hover:font-bold">At KaliKosh Carbons, quality is our top priority. We adhere to rigorous testing standards to ensure that our products meet the highest quality standards.</p>
            <p className="text-lg md:text-xl mb-8 hover:font-bold">Our manufacturing process integrates thorough testing procedures, including compliance with major standards such as ASTM and BSS. We conduct daily testing of customer samples, with periodic checks at every stage of production - from raw material sourcing to final packing.</p>
            <p className="text-lg md:text-xl mb-8 hover:font-bold">Trust KaliKosh Carbons for exceptional quality and reliability in every carbon solution we deliver.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Quality;
