import { useEffect, useState } from 'react';

import { Navbar, Home, AboutUs, Products, Applications, Quality, ContactUs, Socials, Footer, GoToTop } from '../../components';
import scrollToTop from "../../utility/scrollToTop";

function FullPage() {
    const [onPage, setOnPage] = useState(1);
    const [notTop, setNotTop] = useState({ header: false, toTopButton: false });

    useEffect(() => {
        function showHideToTopButton() {
            if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
                setNotTop({ ...notTop, header: true });

                if (document.body.scrollTop > 300 || document.documentElement.scrollTop > 300) {
                    setNotTop({ header: true, toTopButton: true });
                }
            } else {
                setNotTop({ header: false, toTopButton: false });
            }
        }

        window.addEventListener('scroll', showHideToTopButton);

        return () => {
            window.removeEventListener('scroll', showHideToTopButton);
        };
    }, [notTop])

    useEffect(() => {
        scrollToTop();
    }, [onPage])

    return (
        <div className="flex flex-col min-h-screen overflow-hidden">
            <Navbar />
            <Home id="home" />
            <AboutUs id="aboutUs" />
            <Products id="products" />
            <Applications id="applications" />
            <Quality id="quality" />
            <ContactUs id="contactUs" />
            <GoToTop notTop={notTop.toTopButton} />
            <Socials />
            <Footer />
        </div>
    );
}

export default FullPage;