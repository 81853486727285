import { Link as ScrollLink } from 'react-scroll';
import '../../resources/stylesheets/footer.css'

const Footer = (props) => {
    return (
        <footer className="w-full font-raleway py-16 bg-black text-white relative z-10">
            <div className="w-4/5 mx-auto flex flex-col justify-center">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-20 justify-between text-center md:text-left">
                    <div className="flex flex-col">
                        <p className="text-3xl font-extrabold">About Us</p>
                        <p className="footerDesc mt-2 hover:text-green1 transition-colors duration-300 ease-in-out">
                            KaliKosh Carbons aim to specialize in providing innovative and sustainable carbon solutions for various industries, including water treatment, air purification, and industrial applications.
                        </p>
                    </div>
                    <div className="flex flex-col">
                        <div className="mb-6">
                            <p className="text-3xl font-extrabold">Address</p>
                            <p className="footerDesc mt-2">
                                <a
                                    href="https://maps.app.goo.gl/cQRkQsQbrf2FJE8P7"
                                    target='_blank'
                                    rel='noreferrer'
                                    className="hover:text-green1 transition-colors duration-300 ease-in-out"
                                >
                                    Plot No 43, SIDCO Industrial Estate, Bargur, 
                                    Tamil Nadu 635104
                                </a>
                            </p>
                        </div>
                        <div>
                            <p className="text-3xl font-extrabold">Contact</p>
                            <div className="footerDesc mt-2">
                                Email: <a
                                    href='mailto:info@kalikosh.com'
                                    target='_blank'
                                    rel='noreferrer'
                                    className="hover:text-green1 transition-colors duration-300 ease-in-out"
                                >
                                    info@kalikosh.com
                                </a>
                                <p>
                                    Phone/WhatsApp: <a href='https://wa.me/message/KX2ZWVDBMWOHM1' title='Whatsapp' className="hover:text-green1 transition-colors duration-300 ease-in-out">+91 63612 37564</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <p className="text-3xl font-extrabold">Links</p>
                        <ScrollLink
                            to='home'
                            smooth={true}
                            offset={-100}
                            className="block footerLinks mt-2"
                        >
                            Home
                        </ScrollLink>
                        <ScrollLink
                            to='aboutUs'
                            smooth={true}
                            offset={-100}
                            className="block footerLinks mt-2"
                        >
                            About Us
                        </ScrollLink>
                        <ScrollLink
                            to='products'
                            smooth={true}
                            offset={-100}
                            className="block footerLinks mt-2"
                        >
                            Products
                        </ScrollLink>
                        <ScrollLink
                            to='quality'
                            smooth={true}
                            offset={-100}
                            className="block footerLinks mt-2"
                        >
                            Quality
                        </ScrollLink>
                        <ScrollLink
                            to='applications'
                            smooth={true}
                            offset={-100}
                            className="block footerLinks mt-2"
                        >
                            Applications
                        </ScrollLink>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
