import React, { useState, useEffect } from 'react';
import logo from '../../resources/images/black_logo.png';
import makeInIndiaLogo from '../../resources/images/make_in_india.png';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isSticky, setIsSticky] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    useEffect(() => {
        const handleScroll = () => {
            setIsSticky(window.scrollY > 100);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // navbar items with corresponding section IDs
    const navItems = [
        { link: "Home", path: "home" },
        { link: "About Us", path: "aboutUs" },
        { link: "Products", path: "products" },
        { link: "Applications", path: "applications" },
        { link: "Quality", path: "quality" },
        { link: "Contact Us", path: "contactUs" },
    ];

    return (
        <header>
            <nav className={`w-full bg-white lg:px-2 px-4 ${isSticky ? "fixed top-0 left-0 right-0 shadow-lg transition duration-1000 z-50" : ""}`}>
                <div className="flex justify-between items-center md:ml-4">
                    <Link to="/" className="flex items-center space-x-3">
                        <img src={logo} alt="" className="w-32" />
                    </Link>

                    <img src={makeInIndiaLogo} alt="Make in India" className="md:hidden w-auto h-24 rounded-md" />

                    <div className="hidden md:flex space-x-8 items-center">
                        {navItems.map(({ link, path }) => (
                            <ScrollLink
                                to={path}
                                spy={true}
                                smooth={true}
                                offset={-100}
                                key={path}
                                className="block font-raleway text-xl text-gray-700 font-medium hover:text-gray-900 transition duration-300"
                                activeClass="active"
                                style={{ cursor: 'pointer' }}
                            >
                                {link}
                            </ScrollLink>
                        ))}

                        <div className="flex items-center">
                            <div className="w-30 h-30 p-2 text-center flex flex-col justify-center rounded-md">
                                <img src={makeInIndiaLogo} alt="Make in India" className="w-auto h-24 rounded-md" />
                            </div>
                        </div>
                    </div>

                    <div className="md:hidden">
                        <button
                            onClick={toggleMenu}
                            className="text-gray-700 focus:outline-none focus:text-gray-900 transition duration-300"
                        >
                            <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} className="h-10 w-10" />
                        </button>
                    </div>
                </div>

                <div className={`md:hidden fixed w-full inset-x-0 bg-white z-50 overflow-hidden transition-all duration-300 ${isMenuOpen ? "h-screen" : "h-0"}`}>
                    <div className="flex flex-col space-y-4 mt-4">
                        {navItems.map(({ link, path }) => (
                            <ScrollLink
                                key={path}
                                to={path}
                                spy={true}
                                smooth={true}
                                offset={-100}
                                className="text-gray-700 self-center items-center font-medium hover:text-gray-900 transition duration-300 px-4 py-2"
                                onClick={() => setIsMenuOpen(false)}
                                activeClass="active"
                                style={{ cursor: 'pointer' }}
                            >
                                {link}
                            </ScrollLink>
                        ))}

                        {/* <div className="w-full flex items-center self-center">
                            <div className="w-full h-36 p-2 text-center flex flex-row md:flex-col justify-center rounded-md m-4">
                                <img src={makeInIndiaLogo} alt="Make in India" className="w-auto h-auto rounded-md p-1" />
                            </div>
                        </div> */}
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Navbar;