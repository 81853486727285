import React from 'react';
import powdered_activated_carbon from '../../resources/images/powdered_activated_carbon.png'
import granular_activated_carbon from '../../resources/images/granular_activated_carbon.png'
import extruded_activated_carbon from '../../resources/images/extruded_activated_carbon.png'

const Products = () => {
  const products = [
    { id: 1, title: "Powdered Activated Carbon (PAC)", description: "Carbon with a size predominantly less than 0.21mm (70 US mesh). PAC’s are typically used in liquid-phase adsorption applications and offer reduced processing costs.", image: powdered_activated_carbon },
    { id: 2, title: "Granular Activated Carbon (GAC)", description: "Irregular shaped particles with sizes ranging from 0.2 to 5 mm. Used in both gas and liquid phase applications and are popular because they offer clean handling and tend to last longer than PACs.", image: granular_activated_carbon },
    { id: 3, title: "Extruded Activated Carbon (EAC)", description: "Extruded and cylindrical shaped with diameters from 0.8 to 5 mm. Typically used in gas phase reactions, EACs are a heavy-duty activated carbon as a result of the extrusion process.", image: extruded_activated_carbon },
  ];

  return (
    <div className='font-raleway md:px-14 px-4 pb-16 max-w-screen-2xl mx-auto' id='products'>

      {/* products card */}
      <div className='mt-20 md:w-1/2 mx-auto text-center'>
        <h2 className='text-3xl md:text-5xl font-bold text-darkGreen1 mb-3'>Our Products</h2>
        <p className='text-black1 text-lg md:text-xl'>Explore our range of activated carbon products</p>
      </div>

      <div className='mt-14 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 md:w-full mx-auto gap-12 lg:gap-16'>
        {products.map(product => (
          <div key={product.id} className='px-4 py-8 text-center md:w-80 mx-auto md:h-auto rounded-md shadow-lg cursor-pointer hover:-translate-y-5 hover:border-b-4 hover:border-blue1 transition-all duration-300 flex flex-col items-center justify-center'>
            <div className='bg-[#E8F5E9] mb-4 h-25 w-25 mx-auto rounded-tl-3xl rounded-br-3xl'>
              <img src={product.image} alt="" className='w-full h-full object-cover overflow-visible' />
            </div>
            <h4 className='text-2xl font-bold text-black1 mb-2 px-2'>{product.title}</h4>
            <p className='text-lg text-black1 px-2'>{product.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Products;