import React from 'react';
import aboutUsImage from '../../resources/images/aboutus-img.webp';

const AboutUs = () => {
  return (
    <section className="font-raleway bg-neutral-100 py-5 text-black1" id='aboutUs'>
      <div className="container mx-auto px-4 flex flex-col md:flex-row items-center justify-center gap-x-5">
        <div className="md:w-1/2 mb-8 md:mb-0">
          <div className="text-center md:text-left">
            <h2 className="text-3xl md:text-5xl font-bold text-darkGreen1 mb-6">About KaliKosh Carbons</h2>
            <p className="text-lg md:text-xl mb-8 hover:font-bold">KaliKosh Carbons aim to specialize in providing innovative and sustainable carbon solutions for various industries, including water treatment, air purification, and industrial applications.</p>
            <p className="text-lg md:text-xl mb-8 hover:font-bold">Our mission is to lead the way in environmental sustainability by delivering exceptional carbon solutions that exceed the expectations of our clients. With a focus on quality, reliability, and customer satisfaction, we strive to make a positive impact on the world.</p>
            <p className="text-lg md:text-xl mb-8 hover:font-bold">Join us in our journey towards a cleaner and greener future with KaliKosh Carbons.</p>
          </div>
        </div>
        <div className="md:w-1/2">
          <img src={aboutUsImage} alt="Company" className="w-full rounded-lg shadow-lg" />
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
