import React, { useState, useEffect } from 'react';
import carbonImageDesktop from '../../resources/images/kalikosh_desktop_homepage.png';
import carbonImageMobile from '../../resources/images/kalikosh_mobile_homepage.png';
import { Link as ScrollLink } from 'react-scroll';

const Home = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="relative overflow-hidden h-auto">
      <div className="container mx-auto px-4 md:px-8 lg:px-16 flex flex-col md:flex-row justify-center items-center h-auto">
        {/* Image Section */}
        <div className="w-full md:w-1/2 lg:w-1/2 mb-8 md:mb-0 md:order-2">
          <img
            src={windowWidth <= 768 ? carbonImageMobile : carbonImageDesktop}
            alt="Carbon"
            className="w-full md:rounded-lg"
          />
        </div>

        {/* Text Section */}
        <div className="w-full md:w-1/2 lg:w-1/2 text-center md:text-left md:order-1">
          <h1 className="font-raleway text-5xl md:text-6xl font-bold mb-4">KALIKOSH CARBON INDIA PRIVATE LIMITED</h1>
          <p className="font-raleway text-lg md:text-2xl mb-8">Activating Sustainability, Redefining Clean</p>
          <ScrollLink
            to="products"
            smooth={true}
            offset={-100}
            className="inline-block bg-green1 text-white px-8 py-3 rounded-full font-bold hover:bg-darkGreen1 transition duration-300 cursor-pointer"
          >
            Explore More
          </ScrollLink>
        </div>
      </div>
    </div>
  );
}

export default Home;
