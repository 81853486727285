import { useState } from "react";
import { Routes, Route as Link } from "react-router-dom"

import { FullPage } from './components';

const App = () => {
    const [onPage, setOnPage] = useState(1);

    return (
        <>
            {/* <Header onPage={onPage} setOnPage={setOnPage} /> */}

            <Routes>
                <Link path='/' element={<FullPage setOnPage={setOnPage} />} />
            </Routes>

            {/* <Footer onPage={onPage} setOnPage={setOnPage} /> */}
        </>
    )
}

export default App;