import { Email, WhatsApp } from '@mui/icons-material'

import '../../resources/stylesheets/socials.css'

function Socials() {
    return (
        <div className='socials flex flex-col gap-5'>
            <div title='Email' className='icon'>
                <a className='flex flex-center' href="mailto:info@kalikosh.com" target='_blank' rel='noreferrer'>
                    <Email />
                </a>
            </div>
            <div title='Phone' className='icon'>
                <a className='flex flex-center' href='https://wa.me/message/KX2ZWVDBMWOHM1' target='_blank' rel='noreferrer'>
                    <WhatsApp />
                </a>
            </div>
        </div>

    )
}

export default Socials