import { useState } from 'react';
import './contact.css';
import LoadingScreen from './../utils/LoadingScreen';
import { Email, WhatsApp, LocationOn } from '@mui/icons-material';

const Contact = () => {
    const [mapLoaded, setMapLoaded] = useState(false);

    return (
        <section className="font-raleway bg-neutral-100 py-5 text-black1" id='contactUs'>
            <h1 className="heading text-3xl md:text-5xl font-bold text-darkGreen1">Contact Us</h1>
            <div className="location-container relative">
                {!mapLoaded && <LoadingScreen />}
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3895.001400452645!2d78.35013310000001!3d12.516065799999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b00abec99c9b8ef%3A0x9a20b66495c9e8ff!2sKalikosh%20Carbon%20India%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1713036400534!5m2!1sen!2sin"
                    width="100%"
                    height={mapLoaded ? '450' : '0'}
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="Kalikosh Carbon India Pvt. Ltd."
                    onLoad={() => setMapLoaded(true)}
                ></iframe>
            </div>
            <div className="contact-details-container mb-8">
                <h2 className="sub-heading text-2xl text-center text-green1 font-bold mb-4">Contact Details</h2>
                <div className="contact-details-grid">
                    <div className="contact-details-item email text-center" onClick={() => window.location.href = 'mailto:info@kalikosh.com'}>
                        <div className="contact-info">
                            <Email className="contact-icon" />
                            <span>info@kalikosh.com</span>
                        </div>
                    </div>
                    <div className="contact-details-item whatsapp text-center" onClick={() => window.location.href = 'https://wa.me/message/KX2ZWVDBMWOHM1'}>
                        <div className="contact-info">
                            <WhatsApp className="contact-icon" />
                            <span>+91 63612 37564</span>
                        </div>
                    </div>
                    <div className="contact-details-item address text-center">
                        <div className="contact-info">
                            <LocationOn className="contact-icon" />
                            <span>Plot No 43, SIDCO Industrial Estate, Bargur, Tamil Nadu 635104</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact;
